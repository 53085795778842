import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import { TextareaAutosize, TextField, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { getEndUserList } from 'redux/actions/EndUserManagement';
import QuestionAccordion from '../../../@jumbo/components/Common/QuestionAccordion/index';
import LessonAccordion from '@jumbo/components/Common/LessonAccordion';
import { createNewLesson, getAcademyModuleList, getLessonList } from 'redux/actions/AcademyCMS';
import { useHistory } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import './BroadcastChannel.css';
import { createBroadcastChannel } from 'redux/actions/BroadcastChannel';

const variant = 'default';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
    cardContentRoot: {
      padding: '0 !important',
    },

    scrollbarRoot: {
      height: 340,
    },
    badgeRoot: {
      color: theme.palette.common.white,
      borderRadius: 30,
      fontSize: 12,
      padding: '2px 10px',
      display: 'inline-block',
    },
    authThumb: {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        order: 2,
      },
    },
    authContent: {
      padding: 30,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: props => (props.variant === 'default' ? '50%' : '100%'),
        order: 1,
      },
      [theme.breakpoints.up('xl')]: {
        padding: 50,
      },
    },
    titleVersion: {
      // marginBottom: 14,
      letterSpacing: 0.15,
      marginRight: 24,
      color: theme.palette.text.primary,
    },
    headerTitle: {
      fontSize: 20,
      fontStyle: 'bold',
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.common.dark, 0.12),
      },
    },
    formcontrolLabelRoot: {
      '& .MuiFormControlLabel-label': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
    },
    noRecord: {
      textAlign: 'center',
      height: '250px',
      fontSize: 16,
      color: theme.palette.text.disabled,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
      border: 'none',
    },
    tableCellRoot: {
      textAlign: 'center',
    },
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
    },
  },
  tableRowRoot: {
    '& .MuiTableCell-root': {
      fontWeight: 300,
    },
  },
  disabledText1: {
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
  disabledText2: {
    marginLeft: '10px',
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
  backButton: {
    marginBottom: '16px',
  },
}));

const BroadcastChannelPage = () => {
  const classes = useStyles({ variant });
  const history = useHistory();
  const [endUserList, setEndUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();

  const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Broadcast Channel', isActive: true },
  ];

  useEffect(() => {
    const fetchData = () => {
      dispatch(
        getEndUserList('created_at', 'desc', '', 0, -1, '', 3, data => {
          setEndUserList(data?.data?.data || []);
        }),
      );
    };
    fetchData();
  }, [dispatch]);
  console.log('EndUserList', endUserList);

  const getDisplayName = user => {
    if (user.first_name && user.email) {
      return `${user.first_name} (${user.email})`;
    } else if (user.first_name) {
      return `${user.first_name} (No Email)`;
    } else if (user.email) {
      return `No Name (${user.email})`;
    } else {
      return 'No Name/Email';
    }
  };

  const handleSelectChange = (event, value, setFieldValue) => {
    if (value.length === endUserList.length + 1) {
      setSelectAll(true);
      setSelectedUsers(endUserList);
      setFieldValue(
        'users',
        endUserList.map(user => user._id),
      );
    } else if (value.length === 0 || !value.find(v => v._id === 'all')) {
      setSelectAll(false);
      setSelectedUsers(value);
      setFieldValue(
        'users',
        value.map(user => user._id),
      );
    } else if (value.find(v => v._id === 'all')) {
      setSelectAll(!selectAll);
      if (!selectAll) {
        setSelectedUsers(endUserList);
        setFieldValue(
          'users',
          endUserList.map(user => user._id),
        );
      } else {
        setSelectedUsers([]);
        setFieldValue('users', []);
      }
    } else {
      setSelectedUsers(value);
      setFieldValue(
        'users',
        value.map(user => user._id),
      );
    }
  };

  const allOptions = [{ _id: 'all', name: 'Select All' }, ...endUserList];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  });

  const BroadcastChannelFormHandler = async (values, actions) => {
    const formData = new FormData();

    formData.append('title', values.title);
    formData.append('description', values.description);
    const userIds = selectedUsers.length === 0 ? endUserList.map(user => user._id) : selectedUsers.map(user => user._id);
    formData.append('users', JSON.stringify(userIds));
    if (values?.url) {
      formData.append('url', values.url);
    }
    console.log('formData', formData);
    for (let pair of formData.entries()) {
      console.log(`ttttt : ${pair[0]}: ${pair[1]}`);
    }
    dispatch(createBroadcastChannel(formData));
  };

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <PageContainer heading={'Broadcast Channel'} breadcrumbs={breadcrumbs}>
        <Button className={classes.backButton} variant="outlined" onClick={handleBackClick}>
          Back
        </Button>
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: '',
            description: '',
            url: '',
            users: [],
          }}
          validationSchema={validationSchema}
          onSubmit={BroadcastChannelFormHandler}
          // setSubmitting(false)
        >
          {({ errors, isSubmitting, values, setFieldValue }) => {
            console.log('values Form', values, errors);
            return (
              <Form>
                <GridContainer>
                  <Grid item xs={12} lg={12} sm={12}>
                    <CmtCard alignItems="center">
                      <Box p={8} pb={0}>
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <AppTextInput
                                id="title"
                                name="title"
                                size="medium"
                                label={'Title'}
                                value={values?.lessonTitle}
                                onChange={e => setFieldValue('title', e.target.value)}
                                fullWidth
                                margin="0"
                                variant="outlined"
                              />
                              <ErrorMessage name="title" component="div" className="error" />
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <TextareaAutosize
                              className="textAreaControl"
                              aria-label="minimum height"
                              minRows={5}
                              style={{
                                width: '100%',
                                padding: '14px',
                                borderRadius: '4px',
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                fontSize: '14px',
                                color: 'rgba(0, 0, 0, 0.87)',
                              }}
                              placeholder="Description"
                              value={values.description}
                              onChange={e => setFieldValue(`description`, e.target.value)}
                            />
                            <ErrorMessage name={`description`} component="div" className="error" />
                          </Grid>

                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <AppTextInput
                                id="url"
                                name="url"
                                size="medium"
                                label={'URL'}
                                value={values?.url}
                                onChange={e => setFieldValue('url', e.target.value)}
                                fullWidth
                                margin="0"
                                variant="outlined"
                              />
                              <ErrorMessage name="url" component="div" className="error" />
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <Autocomplete
                                multiple
                                options={allOptions}
                                value={selectedUsers} // Use only selectedUsers to manage value
                                onChange={(event, value) => {
                                  if (value.some(option => option._id === 'all')) {
                                    // If "Select All" is selected, select all users
                                    setFieldValue(
                                      'users',
                                      endUserList.map(user => user._id),
                                    );
                                    setSelectedUsers(endUserList);
                                    setSelectAll(true);
                                  } else {
                                    const newSelectedUsers = value.filter(option => option._id !== 'all');
                                    setFieldValue(
                                      'users',
                                      newSelectedUsers.map(user => user._id),
                                    );
                                    setSelectedUsers(newSelectedUsers);
                                    setSelectAll(newSelectedUsers.length === endUserList.length);
                                  }
                                }}
                                disableCloseOnSelect
                                getOptionLabel={option => (option._id === 'all' ? 'Select All' : getDisplayName(option))}
                                renderOption={(option, { selected }) => (
                                  <>
                                    <Checkbox checked={option._id === 'all' ? selectAll : selected} />
                                    <ListItemText primary={option._id === 'all' ? 'Select All' : getDisplayName(option)} />
                                  </>
                                )}
                                renderTags={(value, getTagProps) =>
                                  selectAll
                                    ? [
                                        <Chip
                                          label="Select All"
                                          {...getTagProps({ index: 0 })}
                                          onDelete={() => {
                                            setFieldValue('users', []);
                                            setSelectedUsers([]);
                                            setSelectAll(false);
                                          }}
                                        />,
                                      ]
                                    : value.map((option, index) => (
                                        <Chip label={getDisplayName(option)} {...getTagProps({ index })} key={option._id} />
                                      ))
                                }
                                renderInput={params => (
                                  <TextField {...params} variant="outlined" label="Select Users" placeholder="Users" />
                                )}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box p={8}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SendIcon />}>
                          Send
                        </Button>
                      </Box>
                    </CmtCard>
                  </Grid>
                </GridContainer>
              </Form>
            );
          }}
        </Formik>
      </PageContainer>
    </>
  );
};

export default BroadcastChannelPage;
